<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import { required } from "vuelidate/lib/validators";

export default {
  locales: {
    pt: {
      "New Contract": "Novo Contrato",
      Request: "Solicitar",
    },
    es: {
      "New Contract": "Nuevo Contrato",
      Request: "Solicitar",
    },
  },
  components: { Layout },
  data() {
    return {
      loading: false,
      success: false,

      alert: {
        type: "",
        message: "",
        params: [],
      },

      contract: {
        key: "",
      },
    };
  },
  validations: {
    contract: {
      key: { required },
    },
  },
  methods: {
    requestContract() {
      this.loading = true;
      this.$v.contract.$touch();

      if (this.contract.key) {
        api
          .post("contract", {
            key: this.contract.key,
          })
          .catch((error) => {
            if (error) {
              this.loading = false;
            }
          })
          .then((response) => {
            if (response.data.status == "success") {
              this.success = true;
              this.key = "";
              this.$v.$reset();

              this.$router.push("/contracts/" + response.data.contract.id);
            } else {
              this.alert.type = "alert-danger";
              this.alert.message = response.data.message;
              if (response.data.params) {
                this.alert.params = response.data.params;
              } else {
                this.alert.params = [];
              }

              this.$v.$reset();
            }

            this.loading = false;
          });
      }
    },
  },
  mounted() {
    this.getTypes();
    this.getPeriods();
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t("New Contract") }}</h4>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-7">
        <div class="card">
          <div class="card-body p-4">
            <div v-if="success" class="text-center-sm">
              {{ t("Seu novo contrato foi solicitado com sucesso.") }}
            </div>
            <b-form v-else class="p-3" @submit.prevent="requestContract">
              <div v-if="alert.message" :class="'alert ' + alert.type">
                {{ t(alert.message) }}
              </div>
              <b-form-group
                id="key"
                label-for="key"
              >
                <h5 class="mb-3">Informe a Chave da Licença UDYAT</h5>
                <b-input-group>
                  <b-form-input
                    id="key"
                    size="lg"
                    v-model="contract.key"
                    type="text"
                    inputmode="decimal"
                    class="rounded"
                    :class="{ 'is-invalid': $v.contract.key.$error }"
                    v-mask="'XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX'"
                  ></b-form-input>
                </b-input-group>
                <div
                  v-if="$v.contract.key.$error"
                  class="text-danger font-size-12"
                >
                  <span v-if="!$v.contract.key.required">{{
                    t("Digite a chave da licença.")
                  }}</span>
                </div>
              </b-form-group>
              <div class="mt-4">
                <b-button
                  :disabled="loading == true || this.contract.key === ''"
                  size="lg"
                  type="submit"
                  variant="default"
                >
                  {{ t("Request") }}
                  <b-spinner
                    v-if="loading"
                    small
                    class="ml-2 align-middle"
                    variant="white"
                    role="status"
                  ></b-spinner>
                </b-button>
              </div>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
